import { KmsTypeAddNewMenuSection } from "@kaltura/mediaspace-shared-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    CaptureIllustration,
    ChannelIllustration,
    ExpressCaptureIllustration,
    InteractiveIllustration,
    MeetingRoomIllustration,
    PlaylistIllustration,
    Quizllustration,
    TownHallIllustration,
    UploadIllustration,
} from "@kaltura/mediaspace-shared-add-new";

/**
 * `add new` items, arranged in sections
 */
export const addNewSections: KmsTypeAddNewMenuSection[] = [
    {
        label: "",
        items: [
            {
                label: "Upload",
                iconUrl: "/addNew/upload.svg",
                styledIllustrationSvg: { component: UploadIllustration, name: "UploadSvg" },
                description: "Upload a video from your computer",
                url: "www.kaltura.com",
            },
            {
                label: "Express Capture",
                iconUrl: "/addNew/express_recorder.svg",
                styledIllustrationSvg: { component: ExpressCaptureIllustration, name: "ExpressCaptureSvg" },
                description: "Record a short movie of yourself",
                url: "www.kaltura.com",
                hideOnMobile: true,
            },
            {
                label: "Town Hall",
                iconUrl: "/addNew/townhalls.svg",
                styledIllustrationSvg: { component: TownHallIllustration, name: "TownHallSvg" },
                description: "Create a live broadcast and engage your audience with slides and polls",
                url: "www.kaltura.com",
            },
            {
                label: "Meeting Room",
                iconUrl: "/addNew/meeting.svg",
                styledIllustrationSvg: { component: MeetingRoomIllustration, name: "MeetingRoomSvg" },
                description: "Set up a meeting room for team collaboration",
                url: "www.kaltura.com",
            },
        ],
    },
    {
        label: "Other Creation Tools",
        items: [
            {
                label: "Capture",
                iconUrl: "/addNew/capture.svg",
                styledIllustrationSvg: { component: CaptureIllustration, name: "CaptureSvg" },
                description:
                    "Record a video of your screen and yourself with automated chapters for PowerPoint slides and whiteboard annotations.",
                url: "www.kaltura.com",
            },
            {
                label: "Video Quiz",
                iconUrl: "/addNew/quiz.svg",
                styledIllustrationSvg: { component: Quizllustration, name: "QuizSvg" },
                description: "Add questions and teasers to a video from your library",
                url: "www.kaltura.com",
                hideOnMobile: true,
            },
            {
                label: "Interactive Video",
                iconUrl: "/addNew/interactive.svg",
                styledIllustrationSvg: { component: InteractiveIllustration, name: "InteractiveSvg" },
                description: "Let your viewers be the directors and choose their own path with a branched video",
                url: "www.kaltura.com",
                hideOnMobile: true,
            },
            {
                label: "YouTube",
                iconUrl: "/addNew/youtube.svg",
                illustrationUrl: { dark: "/addNew/youtube_dark.png", light: "/addNew/youtube_light.png" },
                description: "Add a public video from YouTube",
                url: "www.kaltura.com",
            },
        ],
    },
    {
        label: "collections",
        items: [
            {
                label: "Channel",
                iconUrl: "/addNew/channels.svg",
                styledIllustrationSvg: { component: ChannelIllustration, name: "ChannelSvg" },
                description: "Create a new content channel and invite friends to join",
                url: "www.kaltura.com",
            },
            {
                label: "Playlist",
                iconUrl: "/addNew/playlist.svg",
                styledIllustrationSvg: { component: PlaylistIllustration, name: "PlaylistSvg" },
                description: "Create a playlist to collect your favorite videos",
                url: "www.kaltura.com",
            },
        ],
    },
];
