import "./public-path";

import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import ReactDOMClient from "react-dom/client";
import * as MuiMaterial from "@mui/material";
import EmotionStyled from "@emotion/styled";
import * as EmotionReact from "@emotion/react";
import * as StyledComponents from "styled-components";
import { initTranslator } from "@kaltura/ds-react-utils";
import { translate, translatePlural } from "@kaltura/mediaspace-shared-utils";

/**
 * global error handler, final boundary to avoid js errors crashing the page
 * @param error
 */
window.onerror = function (error) {
    console.error("received a fatal Javascript error: ", { error: error });
};

// Init translation utils into DS components lib
initTranslator(translate, translatePlural);

export { React };
export { ReactDOM };
export { ReactDOMServer };
export { ReactDOMClient };
export { MuiMaterial, EmotionStyled, EmotionReact, StyledComponents };

export * as DsLayout from "@kaltura/mediaspace-shared-layout";
export * as HomePage from "./features/home-page/App";
export * as GroupPage from "./features/group-page/App";
export * as ListPage from "./features/list-page/App";
export * as Registration from "./features/registration/App";
export * as Login from "./features/login/App";
export * as Agenda from "./features/agenda/App";
export * as HeaderMenu from "./shared/header-menu/App";
export * as Leaderboard from "./features/leaderboard/App";
export * as PersonalProfile from "./features/personal-profile/App";
export * as Footer from "./shared/footer/App";
export * as HelpPage from "./features/help-page/App";
export * as Kcapture from "./features/kcapture/App";
export * as Recorder from "./features/recorder/App";
export * as UploadMedia from "./features/upload-media/App";
export * as Studio from "./features/studio/App";
export * as PreviewBanner from "./features/preview-banner/App";
export * as ChannelsCollections from "./features/channels-collections/App";
export * as ChannelPage from "./features/channel-page/App";
export * as EditBoardContainer from "./features/board-messages/App";
export * as EmailLogin from "./features/email-login/App";
export * as PrivacyBanner from "./features/privacy-banner/App";
export * as RegistrationEditor from "./features/registration-editor/App";
export * as InviteUsers from "./features/invite-users/App";
export * as AuthBrokerProfilesPage from "./features/auth-broker-profiles-page/App";
export * as RestreamDestinations from "./features/restream-destinations/App";
export * as Certificates from "./features/certificates/App";
export * as PlayerUpgradeTool from "./features/players-upgrade/App";
export * as BulkLoginLinks from "./features/bulk-login-links/App";
export * as Cnc from "./features/cnc/App";
export * as ChannelTV from "./features/channeltv/App";
export * as EditCustomLocale from "./features/edit-custom-locale/App";
export * as Disclaimer from "./features/disclaimer/App";
export * as AuthBrokerLogoutPage from "./features/auth-broker-logout-page/App";
export * as MediaPage from "./features/media-page/App";
export * as ErrorPage from "./features/error-page/App";
export * as GlobalSearch from "./features/global-search/App";
export * as LegacyPort from "./shared/legacy-port/App";
export * as InspireMe from "./features/inspire-me/App";
export * as PlaylistsGrid from "./features/playlists-grid/App";
export * as WaitingRoom from "./features/waiting-room/App";
export * as CheckIn from "./features/event-check-in/App";
export * as MyMedia from "./features/my-media/App";
export * as MediaActions from "./shared/media-actions/App";
export * as ManageSubsciption from "./shared/manage-messaging-subsciption/App";
export * as UI from "./shared/ui/App";
export * as Player from "./shared/player/App";
export * as Utils from "./shared/utils";
export * as AddNewIllustrations from "./features/add-new/App";
export * as ChannelModeration from "./features/channel-moderation/App";
